<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
        <div
          class="
            d-flex
            flex-column
            align-items-center align-items-sm-start
            px-3
            pt-2
            text-white
            min-vh-100
          "
        >
          <a
            href="/"
            class="
              d-flex
              align-items-center
              pb-3
              mb-md-0
              me-md-auto
              text-white text-decoration-none
            "
          >
            <span class="fs-5 d-none d-sm-inline">Menu</span>
          </a>
          <ul
            class="
              nav nav-pills
              flex-column
              mb-sm-auto mb-0
              align-items-center align-items-sm-start
            "
            id="menu"
          >
            <li class="nav-item">
              <router-link to="/TokenAdmin" class="nav-link align-middle px-0">
                <i class="fs-4 bi-house"></i>
                <span class="ms-1 d-none d-sm-inline"
                  >Token - Admin</span
                ></router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/TraderAdmin" class="nav-link align-middle px-0">
                <i class="fs-4 bi-house"></i>
                <span class="ms-1 d-none d-sm-inline"
                  >Trader - Admin</span
                ></router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/User" class="nav-link align-middle px-0">
                <i class="fs-4 bi-house"></i>
                <span class="ms-1 d-none d-sm-inline"
                  >Trader - Usuário</span
                ></router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/Contratos" class="nav-link align-middle px-0">
                <i class="fs-4 bi-house"></i>
                <span class="ms-1 d-none d-sm-inline"
                  >Contratos</span
                ></router-link
              >
            </li>
          </ul>
          <hr />
          <div class="dropdown pb-4">
            <img
              alt="Vue logo"
              src="logo-vertical-ouro-2.png"
              width="50px"
              class="justify-centralized"
            />
          </div>
        </div>
      </div>
      <div class="col py-3">
        <div class="px-4 py-1 my-1 justify-content-left">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  inject: [
    "traderContractAddress",
    "tokenContractAddress",
    "contractUsdcAddress",
    "contractUsdtAddress",
  ],
  setup() {},
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
