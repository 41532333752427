<template>
  <div class="row">
    <h4 class="d-flex justify-content-between align-items-center mb-3">
      Trader - Admin
    </h4>
    <div class="container py-4">
      <div class="p-1 bg-light rounded-3">
        <div class="container-fluid py-1">
          <dl class="row">
            <dt class="col-sm-3">ZAMALEK in contract</dt>
            <dd class="col-sm-9">
              {{ tokensInContract }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 mx-auto">
        <h5>Token Negociated</h5>

        <div class="mb-2"></div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >New Token Negociated</label
          >
          <input
            type="text"
            class="form-control"
            id="newTaxValue"
            aria-describedby="emailHelp"
            v-model="newTokenNegociated"
          />
        </div>
        <label for="exampleInputEmail1" class="form-label"
          >({{ actualTokenNegociated }})</label
        >
        <button
          type="submit"
          class="btn btn-primary"
          @click="updateTokenNegociated"
        >
          Setar
        </button>
      </div>
      <div class="col-lg-2 mx-auto">
        <h5>Receiver Wallet</h5>

        <div class="mb-2"></div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >New Receiver Wallet</label
          >
          <input
            type="text"
            class="form-control"
            id="newTaxValue"
            aria-describedby="emailHelp"
            v-model="newReceiverWallet"
          />
        </div>
        <label for="exampleInputEmail1" class="form-label"
          >({{ actualReceiverWallet }})</label
        >
        <button
          type="submit"
          class="btn btn-primary"
          @click="updateReceiverWallet"
        >
          Setar
        </button>
      </div>
    </div>

    <hr />
    <div class="row">
      <div class="col-lg-2 mx-auto">
        <h5>USDs</h5>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Endereço</label>
          <input
            type="text"
            class="form-control"
            id="zetMintAddressReceiver"
            aria-describedby="emailHelp"
            v-model="newStableContract"
          />
        </div>
        <div class="row mb-3">
          <label for="exampleInputEmail1" class="form-label">USD</label>
          <div class="col-md-6 align-items-center">
            <select
              v-model="selectedStable"
              style="width: 10%"
              @change="changeStable"
              class="w-100 form-select"
            >
              <option v-for="stable in stables" v-bind:key="stable">
                {{ stable }}
              </option>
            </select>
          </div>
        </div>
        ({{ actualStableContract }})
        <button type="submit" class="btn btn-primary" @click="updateStable">
          Setar
        </button>
      </div>
      <div class="col-lg-2 mx-auto">
        <h5>Roles</h5>

        <div class="mb-2">
          <label for="exampleInputEmail1" class="form-label">Ação</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              v-model="rolesAcaoId"
              value="1"
            />
            <label class="form-check-label" for="flexRadioDefault1">
              Adicionar
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              v-model="rolesAcaoId"
              value="2"
              id="flexRadioDefault2"
            />
            <label class="form-check-label" for="flexRadioDefault2">
              Remover
            </label>
          </div>
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Endereço</label>
          <input
            type="text"
            class="form-control"
            id="zetMintAddressReceiver"
            aria-describedby="emailHelp"
            v-model="selectedAddressRole"
          />
        </div>
        <div class="row mb-3">
          <label for="exampleInputEmail1" class="form-label">Role</label>
          <div class="col-md-6 align-items-center">
            <select
              v-model="selectedRole"
              style="width: 10%"
              class="w-100 form-select"
            >
              <option v-for="role in roles" v-bind:key="role">
                {{ role }}
              </option>
            </select>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" @click="changeRole">
          Setar
        </button>
      </div>

      <div class="col-lg-2 mx-auto"></div>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-2 mx-auto">
        <h5>Withdraw Emergência</h5>
        <div class="mb-2">
          {{ eTokenQuantity }}
        </div>

        <div class="mb-2">
          <button type="submit" class="btn btn-primary" @click="withdrawEToken">
            Withdraw
          </button>
        </div>
      </div>
      <div class="col-lg-2 mx-auto">
        <h5>Modificar Token Emergência</h5>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Endereço do token</label
          >
          <input
            type="text"
            class="form-control"
            aria-describedby="emailHelp"
            v-model="eTokenAddress"
          />
        </div>
        <button type="submit" class="btn btn-primary" @click="changeEToken">
          Modificar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "../domain/web3";
import contractToken from "../abis/token.json";
import contractTrader from "../abis/trader.json";
import { ethers } from "ethers";

export default {
  name: "Admin",
  inject: [
    "traderContractAddress",
    "contractUsdcAddress",
    "contractUsdtAddress",
    "tokenContractAddress",
  ],
  data() {
    return {
      rolesAcaoId: 0,
      roles: ["Admin"],
      selectedRole: "",
      selectedAddressRole: "",
      newReceiverWallet: "",
      actualReceiverWallet: "",
      newTokenNegociated: "",
      actualTokenNegociated: "",
      stables: ["USDT", "USDC"],
      selectedStable: "",
      selectedStableId: 0,
      actualStableContract: "",
      newStableContract: "",
      eTokenAddress: "",
      eTokenQuantity: 0,
      tokensInContract: 0,
    };
  },
  props: {},

  async mounted() {
    await this.getReceiverWallet();
    await this.getTokenNegociated();
    await this.getTokensInContract();
    await this.getETokenQuantity();
  },
  methods: {
    async getETokenQuantity() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        await contract.methods
          .EToken()
          .call({ from: account }, function (err, res) {
            console.log("etoken:", res);
          });

        const eTokenValue = await contract.methods
          .getEtokenBalance()
          .call({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
        this.eTokenQuantity = eTokenValue / 10 ** 18;
      }
    },
    async getTokensInContract() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.tokenContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        const tic = await contract.methods
          .balanceOf(this.traderContractAddress)
          .call({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res balanceOf  traderContractAddress", res);
            }
            return res;
          });
        let ticFormated = ethers.utils.formatUnits(tic, 18);
        this.tokensInContract = ticFormated;
      }
    },
    async changeEToken() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        await contract.methods
          .modifiyEToken(this.eTokenAddress)
          .send({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
      }
    },
    async withdrawEToken() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        await contract.methods
          .withDrawEtoken()
          .send({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
      }
    },
    async changeRole() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractToken,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        let decodedRole = "";
        if (this.selectedRole === "Admin") decodedRole = "0x00";

        if (this.rolesAcaoId == 1) {
          await contract.methods
            .grantRole(decodedRole, this.selectedAddressRole)
            .send({ from: account }, function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              } else {
                console.log("res", res);
              }
              return res;
            });
        } else if (this.rolesAcaoId == 2) {
          await contract.methods
            .revokeRole(decodedRole, this.selectedAddressRole)
            .send({ from: account }, function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              } else {
                console.log("res", res);
              }
              return res;
            });
        }
      }
    },
    async updateTaxWallet() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        await contract.methods
          .updateTaxWallet(this.newTaxWallet)
          .send({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
        this.getTaxWallet();
      }
    },
    async getReceiverWallet() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        const mWalletR = await contract.methods
          .getReceiverWallet()
          .call({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res tickerContract", res);
            }
            return res;
          });
        this.actualReceiverWallet = mWalletR;
      }
    },
    async updateReceiverWallet() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        await contract.methods
          .updateReceiverWallet(this.newMainWallet)
          .send({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
        this.getMainWallet();
      }
    },
    async getTokenNegociated() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        const tokenR = await contract.methods
          .getTokenNegociated()
          .call({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res tickerContract", res);
            }
            return res;
          });
        this.actualTokenNegociated = tokenR;
      }
    },
    async updateTokenNegociated() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        await contract.methods
          .TokenNegociated(this.newTokenNegociated)
          .send({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
        this.getTokenNegociated();
      }
    },
    async getStable() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        if (this.selectedStableId == 1) {
          const usdc = await contract.methods
            .tokenUsdc()
            .call({ from: account }, function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              } else {
                console.log("res usdc contract", res);
              }
              return res;
            });
          this.actualStableContract = usdc;
        } else {
          const usdt = await contract.methods
            .tokenUsdt()
            .call({ from: account }, function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              } else {
                console.log("res usdt contract", res);
              }
              return res;
            });
          this.actualStableContract = usdt;
        }
      }
    },
    async changeStable() {
      if (this.selectedStable === "USDC") {
        this.selectedStableId = 1;
      } else if (this.selectedStable === "USDT") {
        this.selectedStableId = 2;
      }
      this.getStable();
    },
    async updateStable() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        await contract.methods
          .updateTokenUsd(this.selectedStableId, this.newStableContract)
          .send({ from: account }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
        this.getStable();
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || charCode !== 100 || charCode !== 190)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
