<template>
  <div>
    <h4 class="d-flex justify-content-between align-items-center mb-3">
      Trader - Usuário
    </h4>

    <div class="col-lg-6 mx-auto">
      <div class="col-md-6 col-lg-6 order-md-last">
        <ul class="list-group mb-6">
          <li class="list-group-item d-flex justify-content-between lh-sm">
            <div class="row mb-3">
              <div class="col-md-6 align-items-center">Escolha uma stable:</div>
              <div class="col-md-6 align-items-center">
                <select
                  v-model="selectedStable"
                  style="width: 10%"
                  @change="changeStable"
                  class="w-100 form-select"
                >
                  <option v-for="stable in stables" v-bind:key="stable">
                    {{ stable }}
                  </option>
                </select>
              </div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <div>
              <h6 class="my-0">USD</h6>
            </div>
            <input
              type="text"
              @change="calcTokenValue"
              v-model="stableQnt"
              @keypress="isNumber($event)"
              maxlength="7"
              class="form-control input-sm justify-content-between"
              style="width: 40%"
            />
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <div>
              <h6 class="my-0">Tokens</h6>
            </div>
            <span class="text-muted">{{ this.tokenQnt }} ZAMALEK</span>
          </li>

          <button
            type="button"
            @click="buy"
            v-if="buyWithShow"
            class="btn btn-primary"
          >
            Comprar
          </button>
          <button
            @click="stableApprove"
            v-if="!buyWithShow"
            type="button"
            class="btn btn-primary"
          >
            Aprovar
          </button>
        </ul>
        <ul></ul>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "../domain/web3";
import contractTrader from "../abis/trader.json";
import contractToken from "../abis/token.json";
import { ethers } from "ethers";

export default {
  name: "TraderUser",
  inject: [
    "traderContractAddress",
    "contractUsdcAddress",
    "contractUsdtAddress",
  ],
  data() {
    return {
      stableQnt: 0,
      tokenQnt: 0,
      stables: ["USDT", "USDC"],
      selectedStable: "",
      selectedStableId: 0,
      approveShow: true,
      buyWithShow: false,
    };
  },
  props: {},

  async mounted() {},
  methods: {
    async calcTokenValue() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        const staleQntFormated = ethers.utils.parseUnits(
          this.stableQnt.toString(),
          6
        );
        console.log("staleQntFormated", staleQntFormated);

        const tokenValue = await contract.methods
          .calculateTokenQntToBuyWithUsd(staleQntFormated)
          .call(function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            console.log("res value token", res);
            return res;
          });

        console.log("tokenValue", tokenValue);

        const tkvFormated = ethers.utils.formatUnits(tokenValue, 18);

        console.log("tkvFormated", tkvFormated);

        if (tkvFormated.length > 5)
          this.tokenQnt = tkvFormated.slice(0, tkvFormated - 5);
        else this.tokenQnt = tkvFormated;
      }
    },
    async changeStable() {
      if (this.selectedStable === "USDC") {
        this.selectedStableId = 1;
      } else if (this.selectedStable === "USDT") {
        this.selectedStableId = 2;
      }
      this.checkAllowance();
    },
    async checkAllowance() {
      const web3 = await Web3();

      let contract = "";

      if (this.selectedStableId == 1) {
        contract = new web3.eth.Contract(
          contractToken,
          this.contractUsdcAddress
        );
      } else if (this.selectedStableId == 2) {
        contract = new web3.eth.Contract(
          contractToken,
          this.contractUsdtAddress
        );
      }

      var account = (
        await web3.currentProvider.request({ method: "eth_requestAccounts" })
      )[0];

      let allowedValue = await contract.methods
        .allowance(account, this.traderContractAddress)
        .call({ from: account });

      if (allowedValue > 0) {
        this.approveShow = false;
        this.buyWithShow = true;
      } else {
        this.approveShow = true;
        this.buyWithShow = false;
      }
    },
    async buy() {
      const web3 = await Web3();
      if (!web3) {
        alert("not found web3");
      } else {
        const web3 = await Web3();

        let contract = new web3.eth.Contract(
          contractTrader,
          this.traderContractAddress
        );

        var account = (
          await web3.currentProvider.request({ method: "eth_requestAccounts" })
        )[0];

        const staleQntFormated = ethers.utils.parseUnits(
          this.stableQnt.toString(),
          6
        );
        console.log("staleQntFormated", staleQntFormated);

        var gasPrice = await web3.eth.getGasPrice();

        await contract.methods
          .buyWithStable(staleQntFormated, this.selectedStableId)
          .send({ from: account, gasPrice: gasPrice * 2 }, function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("res", res);
            }
            return res;
          });
      }
    },
    async stableApprove() {
      const web3 = await Web3();

      let contractAdddress = "";

      if (this.selectedStableId == 1) {
        contractAdddress = this.contractUsdcAddress;
      } else if (this.selectedStableId == 2) {
        contractAdddress = this.contractUsdtAddress;
      }

      let contractTokenWeb3 = new web3.eth.Contract(
        contractToken,
        contractAdddress
      );

      var account = (
        await web3.currentProvider.request({ method: "eth_requestAccounts" })
      )[0];

      const staleQntFormated = ethers.utils.parseUnits(
        this.stableQnt.toString(),
        6
      );

      var gasPrice = await web3.eth.getGasPrice();

      await contractTokenWeb3.methods
        .approve(this.traderContractAddress, staleQntFormated)
        .send({ from: account, gasPrice: gasPrice * 2 })
        .then(() => {
          this.checkAllowance();
        });
    },
    // async selectMaxValue() {

    //   this.stableQnt = Math.floor(ethers.utils.formatUnits(this.stableQnt, 6));

    //   // const web3 = await Web3();

    //   // let contractStable = "";

    //   // if (this.selectedStableId == 1) {
    //   //   contractStable = new web3.eth.Contract(
    //   //     contractToken,
    //   //     this.contractUsdcAddress
    //   //   );
    //   // } else if (this.selectedStableId == 2) {
    //   //   contractStable = new web3.eth.Contract(
    //   //     contractToken,
    //   //     this.contractUsdtAddress
    //   //   );
    //   // }

    //   // var account = (
    //   //   await web3.currentProvider.request({ method: "eth_requestAccounts" })
    //   // )[0];

    //   // await contractStable.methods
    //   //   .balanceOf(account)
    //   //   .call({ from: account })
    //   //   .then((resp) => {

    //   //   });
    // },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || charCode !== 100 || charCode !== 190)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
html,
body {
  margin: 0;
  width: 100%;
}

body {
  background-color: #000;
  font-family: "Cantarell", sans-serif;
}

.box-token {
  background-color: #3a3a3a;
  color: #fff;
}

.logo {
  background-color: #282828;
  padding: 20px;
  display: inline-block;
  margin: -80px 0 0 0;
}

.token-price {
  border: 2px solid #fff;
  padding: 10px;
}

.token-value {
  color: #000;
  padding: 12px 15px;
  background-color: #fff;
  font-weight: bold;
}

.form-lgn input,
.form-lgn select {
  border-radius: 0;
  background-color: #474747;
  color: #fff;
  border: 2px solid #000;
}

.form-lgn input:disabled {
  border: 0;
  background-color: #474747;
  color: #fff !important;
}

.form-lgn button.btn-buy {
  background-color: #bba346;
  color: #fff;
  border: 0;
  border-radius: 0;
}
</style>
